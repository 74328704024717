import React, {Component} from '@core/ReactComponent';
import cx from 'classnames/index';
import {GiftFilled} from '@ant-design/icons';
import './index.css';

//import AppDataEmpty from '@com/app-data-empty';

class Module extends Component {
    // constructor(props, context) {
    //     super(props, context);
    //     let self = this;
    // }

    static createInitState(props, context) {
        return {};
    }

    static initCustomFunction(self) {
        return {}
    }

    //UNSAFE_componentWillReceiveProps(props) {}
    //UNSAFE_componentWillMount() {
    //}
    //componentDidMount() {
    //   let self=this; 
    //}

    //componentWillUnmount() { }
    render() {
        let self = this;
        //let state = self.state;
        //let {} = self.state;
        // let {match,location}=self.props;
        return (
            <div className={cx('module-view', 'mv-point_tip', self.props.className)}>
                <div className={cx('inner-wrap','animated','bounceIn')}>
                    <div className={'icon-wrap'}><GiftFilled className={'point-icon'}/></div>
                    <div className={'txt-wrap'}><span>获得<i className={'t-point'}>{self.props.points}</i>积分</span></div>
                </div>
            </div>
        );
    }
}

export default Module;
