const Utils ={
    valid:{
        isPhone:(str,len=11)=> {
            if(str && str.length===len){
                return new RegExp(/^1[2-9][0-9]\d{4,8}$/).test(str);
            }else{
                return false;
            }
        }
    },
    randomNumber: (Min,Max)=>{
        let Range = Max - Min;
        let Rand = Math.random();
        let num = Min + Math.round(Rand * Range);
        return num;
    },
    randomUnRepeatNumber: (Min,Max,Count=1)=>{
        if((Max-Min+1)<Count){
            Max = Count;
        }
        let numbers=[];
        while (Count>0){
            let num = Utils.randomNumber(Min,Max);
            if(numbers.indexOf(num)===-1){
                numbers.push(num);
                Count--;
            }
        }
        return numbers;
    },
    intValue(num) {
        if(num > Number.MAX_SAFE_INTEGER || num < Number.MIN_SAFE_INTEGER) {
            return num &= 0xFFFFFFFF;
        }
        return num;
    },
    hashCode(str){
        let hash = 0;
        if(typeof str==='string' && str) {
            for (let i = 0; i < str.length; i++) {
                hash = hash * 31 + str.charCodeAt(i);
                hash = this.intValue(hash);
            }
        }
        return hash;
    },
    replace:{
        zhToEN:(text)=>{
            let t=[];
            let conf = {
                "’":"'",
                "‘":"'",
                "。":".",
                "，":",",
                "？":"?",
                "：":":",
                "；":";",
                "！":"!",
                "”":'',
                "“":'',
                "（":'(',
                "）":')',
                "【":'[',
                "】":']',
            }
            if('string' === typeof text && text.length){
                for(let i in text){
                    if(!isNaN(i)){
                        let c = text.charCodeAt(i);
                        let cStr = String.fromCharCode(c);
                        if(conf[cStr]){
                            t.push(conf[cStr]);
                        }else if(c===34){
                            t.push(conf['']);
                        }else{
                            t.push(cStr);
                        }
                    }
                }
            }
            return t.join('');
        }
    },
    objectWalk(treeObj,childsKey,callback){
        if(typeof treeObj !== 'object'){
            throw new Error('object walk need a object to walk');
        }
        let tf = typeof childsKey;
        if(!childsKey || !(tf === 'string' || tf === 'function')){
            throw new Error('object walk need a string or function childsKey to walk');
        }
        if(typeof callback !== 'function'){
            throw new Error('object walk need a callback function');
        }
        let walk = function (obj,key,parents,depth,index,cb) {
            let child;
            if(typeof key==='function'){
                child=key(obj);
            }else{
                child=obj[key];
            }
            if(obj && cb(obj,parents,depth,index)!==false){
                let childParents=parents.slice(0);
                let childDepth=depth+1;
                childParents.push(obj);
                if(typeof child ==='object' && !isNaN(child.length)){
                    let len=child.length;
                    for(let i=0;i<len;i++){
                        if(walk(child[i],key,childParents,childDepth,i,cb)===false){
                            break;
                        }
                    }
                }else if(child){
                    walk(child,key,childParents,childDepth,0,cb);
                }
            }else{
                return false;
            }
        };
        walk(treeObj,childsKey,[],0,0,callback);
    },
    shuffle(arr) {
        arr.sort((a, b)=>{
            return Math.random() >0.4 ? 1 : -1
        });
        return arr;
    },
    getRunningENV(queryEnvKey,envConfig,hosts) {
        let env;
        let hostEnvKey;
        if(queryEnvKey && envConfig[queryEnvKey]){
            hostEnvKey = queryEnvKey;
        }else{
            let port=window.location.port;
            let host=window.location.hostname;
            if(hosts){
                if(hosts[host]){
                    hostEnvKey = hosts[host];
                }
            }
            if(!hostEnvKey){
                if(port){
                    hostEnvKey = 'dev';
                }else if(host.indexOf('dev.')===0){
                    hostEnvKey = 'dev';
                }else if(host.indexOf('test.')===0){
                    hostEnvKey = 'test';
                }else if(host.indexOf('pre.')===0){
                    hostEnvKey = 'pre';
                }else{
                    hostEnvKey = 'prod';
                }
            }
        }
        env = envConfig[hostEnvKey];
        env.envKey = hostEnvKey;
        return env;
    },
    getByPath(val, path) {
        if (typeof path == 'string') {
            path = path.split('.');
        }
        let key = path.shift();
        let sub = val[key]!==undefined ? val[key] : undefined;
        if (sub === undefined) {
            return sub;
        }
        if (path.length) {
            return Utils.getByPath(sub, path);
        }
        return sub;
    },
    getUrlByObj(obj){
        let query = [];
        for(let key in obj){
            if(obj[key] === undefined || !''.concat(obj[key]).length) continue
            query.push(''.concat(key,'=',obj[key])) 
        }
        return query.join('&')
    },
    getSearchObj(searchStr){
        let tempUrl = decodeURIComponent(searchStr || window.location.hash || window.location.search);
        let reg =  /[\?&]([^=]+)[=\s]+([^&]+);?/g;
        let tempMap = {};
        while (reg.exec(tempUrl)) tempMap[''.concat(RegExp.$1).trim()] = ''.concat(RegExp.$2).trim(); 
        return tempMap;
    },
    checkPlatform:()=>{
      let ua= window.navigator.userAgent.toLowerCase();
      let isMobile = ua.indexOf('mobile')>=0;
      let isAndroid = ua.indexOf('android')>=0;
      let isIPad = ua.indexOf('ipad')>=0;
      let isIPod = ua.indexOf('ipod')>=0;
      let isIPhone = ua.indexOf('iphone')>=0;
      let isIOS = isIPad || isIPhone || isIPod;
      let isApp = ua.indexOf('hmfapp')>=0;
      let nativeVer =  '';
      if(isApp){
        let hmfStr = ua.substr('hmfapp').split(' ');
          hmfStr.forEach((str)=>{
              if(str){
                let tmp = str.split(':');
                if(tmp.length===2 && tmp[0]==='hmfapp'){
                    nativeVer=tmp[1];
                }
              }
          })
      }
      return {isMobile,isAndroid,isIOS,isIPad,isIPhone,isIPod,isApp,nativeVer}
    }
}

export default Utils