import React, {Component} from '@core/ReactComponent';
import cx from 'classnames';
import dayjs from 'dayjs';
import dayjsZhCN from 'dayjs/locale/zh-cn';
import Utils from '@core/Utils';
import API from '@core/API';
import AppService from '@sev/AppService';
import Apps from '@page/app';
import AppGlobalAudioPlayer from '@com/app-global-audio-player';
import AppDrawerWraper from '@com/app-drawer-wraper';
// import AppSnackMessage from '@com/app-snack-message';
import AppMaskWrapper from '@com/app-mask-wrapper';
import PointTip from '@mod/hmf_wear/point/point_tip';

import './index.css';

const serviceCache={};

dayjs.locale(dayjsZhCN);
class Module extends Component {
    constructor(props,context){
        super(props,context);
        this.tempValues = {};
        //初始化APP运行环境
    }
    createInitState(props, context) {
        let self=this;
        let AppConf=window.hmfENVConfig;
        //取地址栏search参数（可以看做启动参数）;
        let querysObj = Utils.getSearchObj();
        //合并特定域名映射的默认参数
        querysObj = Object.assign({},AppConf.apiInstance.hostQuerys[window.location.hostname],querysObj);
        //确定环境;
        // let env = Utils.getRunningENV(querysObj.env,AppConf.apiInstance.env,AppConf.apiInstance.hosts);
        let platform = Utils.checkPlatform();
        self.APP_ENV = {
            env:    AppConf.apiInstance.env,
            config: AppConf,
            querys: querysObj,
            platform
        };
        self.getVisitAppKey(querysObj,AppConf);
        window.jsNativeBridge.ver = platform.nativeVer || '';
        //初始化API对象(对象动态生成的接口调用函数，数据缓存等功能，);
        let APIInstance = new API(AppConf, self.APP_ENV.env);
        //设置全局接口错误处理函数
        APIInstance.setGlobalError(function (err) {
            console.error(err);
            self.tempValues.btnLock = false;
            if(err && err.request && err.request.status!==200){
                switch (err.request.status){
                    case 404:
                        self.alertMessage('请求资源不存在！');
                        break;
                    case 401:
                        self.logout();
                        break;
                    default:
                        self.alertMessage(`网络错误！[${err.request.status}:${err.message}]`);
                }
            }else{
                self.alertMessage(`未知错误！[${err.message}]`);
            }
        });
        //创建初始化service对象方法（用于公用单例api对象）
        self.createService = function (ApiInstance,env, ServiceClass){
            if(ServiceClass.displayName==='AudioRecordService'){
                if(!self.tempValues.hasAskDevicePermissions){
                    self.checkDevicePermissions([1,2],(flag)=>{
                        if(!flag){
                            self.alertMessage('未取得设备相关权限，口语评测以及音视频播放将无法正常使用！',true);
                        }else{
                            self.tempValues.hasAskDevicePermissions = true;
                        }
                    })
                }
            }
            if(serviceCache[ServiceClass.displayName]){
                if(ServiceClass.displayName==='AudioRecordService'){
                    serviceCache[ServiceClass.displayName].reInit && serviceCache[ServiceClass.displayName].reInit()
                }
                return serviceCache[ServiceClass.displayName];
            }else{
                return serviceCache[ServiceClass.displayName] = new ServiceClass(ApiInstance,env, self);
            }
        }.bind(self,APIInstance,self.APP_ENV);
        //创建App全局业务层对象（登入，登出等业务）
        self.service = self.createService(AppService);
        self.service.api.setGlobalHeaders({ 'X-LX-App': self.APP_ENV.appKey})
        return {appKey:self.APP_ENV.appKey,channelKey:self.APP_ENV.channelKey,appRef:self.APP_ENV.appRef};
    }
    initCustomFunction(self){
        return {
            alertMessage:(message,autoClose=true)=>{
                // if(message){
                //     self.appSnackMessage && self.appSnackMessage.showMessage(message,autoClose);
                // }
            },
            alertNotice:(res,autoClose=true)=>{
                // let _notice=res && (res._notice || res._error)? res._notice[0] || res._error[0] : undefined;
                // if(_notice && _notice.message){
                //     let msg =_notice.message.errmsg || _notice.message.toString();
                //     self.appSnackMessage && self.appSnackMessage.showMessage(msg,autoClose);
                // }
            },
            alert:(msg)=>{

            },
            openMask:(content,option)=>{
                return self.maskWrapper && self.maskWrapper.open(content,option);
            },
            closeMask:()=>{
                self.maskWrapper && self.maskWrapper.close();
            },
            logout:()=>{
                window.location.reload();
            },
            // sendSMS:(phoneNumber,message='',callback)=>{
            //     if(phoneNumber && message && message.length){
            //         self.execNativeApi('sendSMS',{phoneNumber, message},callback);
            //     }
            // },
            // getSIMPhoneNumber:()=>{
            //     self.execNativeApi('getPhoneNumber',{},(res)=>{
            //         if(res && res.phoneNumber && res.phoneNumber.length){
            //             self.APP_ENV.simPhoneNumber=res.phoneNumber.match(/1\d{10}/g)[0];
            //         }else{
            //             self.APP_ENV.simPhoneNumber='';
            //         }
            //     });
            // },
            getVisitAppKey:(querys,AppConf)=>{
                let appKey = querys.app;
                let channelKey='';
                let channelStr=querys.cooperation; //合作渠道标识
                let appConf = AppConf.apiInstance.app[appKey];
                if(!appConf){
                    appKey='hmf_wear';
                    appConf = AppConf.apiInstance.app[appKey];
                }
                if(channelStr && AppConf.apiInstance.channel[appKey] && AppConf.apiInstance.channel[appKey][channelStr]){
                    //使用合作渠道特性配置；
                    channelKey=channelStr;
                    appConf = Object.assign(appConf,AppConf.apiInstance.channel[appKey][channelStr]);
                }
                self.APP_ENV.appKey=appKey;
                self.APP_ENV.appRef=querys.ref ||'';
                self.APP_ENV.channelKey=channelKey;
                self.APP_ENV.app = appConf;
            },
            execNativeApi:(api,param,callback)=>{
                let APP_ENV = self.APP_ENV;
                let {platform} = APP_ENV;
                console.log(`execNativeApi:${api} -> param:${JSON.stringify(param)}`);
                let cb = (res)=>{
                    let data;
                    if(typeof res ==='string'){
                        console.log(`execNativeApi:${api} -> response string:${res}`);
                        data = JSON.parse(res);
                    }else{
                        console.log(`execNativeApi:${api} -> response object:${JSON.stringify(res)}`);
                        data = res;
                    }
                    callback && callback(data);
                };
                if(platform.isApp && window.androidNativeBridge){
                    window.androidNativeBridge.callHandler(api,param,cb);
                }else if(platform.isApp && window.iosNativeBridge){
                    window.iosNativeBridge.callHandler(api,param,cb);
                }else{
                    // self.alertMessage('非app环境,无法使用该功能！')
                    console.log(`${api},非app环境或未找到原生依赖`);
                    callback && callback(null);
                }
            },
            registNativeApi:(api,callback)=>{
                let APP_ENV = self.APP_ENV;
                let {platform} = APP_ENV;
                console.log(`registNativeApi:${api}`);
                if(platform.isApp && window.androidNativeBridge){
                    window.androidNativeBridge.registerHandler(api,(data,resCallback)=>{
                        let res = data && typeof data ==='string'? JSON.parse(data):data;
                        console.log(`registNativeApi ${api} -> callback:${JSON.stringify(res)}`);
                        callback && callback(res,resCallback);
                    });
                }else if(platform.isApp && window.iosNativeBridge){
                    window.iosNativeBridge.registerHandler(api,(data,resCallback)=>{
                        let res = data && typeof data ==='string'? JSON.parse(data):data;
                        console.log(`registNativeApi ${api} -> callback:${JSON.stringify(res)}`);
                        callback && callback(res,resCallback);
                    });
                }else{
                    console.log(`${api},非app环境或未找到原生依赖`);
                }
            },
            checkDevicePermissions:(types,callback)=>{
                let param ={type:types};
                self.execNativeApi('checkPermission',param,(res)=>{
                    if(res){
                        console.log('devicePermissions res:',res);
                        let notAllows = [];
                        if(res.status.length){
                            param.type.forEach((v,i)=>{
                                if(!res.status[i]){
                                    notAllows.push(v);
                                }
                            })
                        }
                        if(notAllows.length){
                            self.execNativeApi('requestPermission',{type:notAllows},(res2)=>{
                                if(res2 && res2.status){
                                    self.registNativeApi('onRequestPermissionsResult',(res3)=>{
                                        if(res3 && res3.status){
                                            let success = true;
                                            notAllows.forEach((v,i)=>{
                                                if(!res3.status[i]){
                                                    success = false;
                                                }
                                            });
                                            if(success){
                                                callback && callback(true);
                                            }else {
                                                callback && callback(false);
                                            }
                                        }
                                    })
                                }else{
                                    callback && callback(true);
                                }
                            })
                        }else{
                            callback && callback(true);
                        }
                    }else{
                        callback && callback(true);
                    }
                })
            },
            openDrawer:(module,onClose,drawerProps)=>{
                self.appDrawerWraper && self.appDrawerWraper.open(module,onClose,drawerProps);
            },
            closeDrawer : (clear)=>{
                self.appDrawerWraper && self.appDrawerWraper.close(clear);
            },
            shouldAddPoint:()=>{
                let {appKey} = self.state;
                let {roleLevel,features,school,castle,user} = self.profile;
                if(features && features.point && features.point.enable && school && castle && user){
                    let param = {
                        app:appKey,
                        role:roleLevel,
                        userId:user._id,
                        province:school.province,
                        city:school.city,
                        county:school.county,
                        schoolId:school._id,
                        castleId:castle._id,
                        body:{
                            schoolName:school.name,
                            castleName:`${castle.displayName}${castle.name}`,
                            userName:user.name,
                        }
                    };
                    self.service.point.shouldAddPoint(param,(res)=>{
                        if(res.data && res.data[0]){
                            let result = res.data[0];
                            if(result.add>0){
                                self.openMask(<PointTip points={result.add}/>,{
                                    cancelClose: true,
                                    autoClose: 5000
                                })
                            }
                        }
                    })
                }
            },
            netLog:(log,callback)=>{
                let {nativeVer} = self.APP_ENV.platform;
                let {school,imei,features,access,subs}=self.profile;
                if(school && imei){
                    if(log && log.category && log.action && log.body){
                        let shouldSave = true;
                        if(log.action==='access'){
                            log.body.upInfo={
                                provinceName:school.province,
                                cityName:school.city,
                                countyName:school.county
                            };
                            log.body.access=access;
                            log.body.subs=subs;
                            if(self.tempValues[`log_${log.category}`]){
                                shouldSave=false;
                            }
                        }
                        if(shouldSave){
                            log.app='hmf_wear';
                            log.source=imei.category;
                            log.deviceId=imei.imei;
                            log.version= nativeVer || '';
                            self.service.log.create(log,(res)=>{
                                if(res.data){
                                    self.tempValues[`log_${log.category}`] = true;
                                    let logKey=`${log.category}_${log.action}`;
                                    switch (logKey) {
                                        case 'audio_room_play':
                                        case 'word_room_play':
                                        case 'reciting_room_voice':
                                            if(features && features.point && features.point.enable){
                                                setTimeout(self.shouldAddPoint,2000);
                                            }
                                            break;
                                        default:
                                    }
                                }
                                callback && callback(res);
                            });
                        }else{
                            callback && callback();
                        }
                    }else{
                        callback && callback();
                        console.log('非法日志格式！',log);
                    }
                }
            },
            clearLocalDir:()=>{
                // let param = {api:'getStorageFiles', param : {path : path}};
                //{"files":"[/storage/emulated/0/Android/data/com.lianxue.hmfen.sc/files/record/record.wav]","count":1}
                // let param = {api:'deleteStorage', param : {path : path}};
                let param = {path:'/storage/emulated/0/Android/data/com.lianxue.hmfen.sc/files/record'};
                self.execNativeApi('getStorageFiles',param,(res1)=>{
                    if(res1 && res1.files && res1.count){
                        self.execNativeApi('deleteStorage',param,(res2)=>{
                            console.log('deleteStorage',res2);
                        });
                    }
                })
            }
        }
    }
    getChildContext() {
        return {
            app:this,
            parent:this
        };
    }
    // componentDidCatch(error, info) {
    // }
    componentDidMount(){
        let self=this;
        // console.log(dayjs().date());
        // console.log(dayjs('2022-01-10 13:00:00').unix()-dayjs('2022-01-10 12:59:00').unix());
        if(window.iosNativeBridge){
            console.log('iosNativeBridge ready');
        }
        if(window.androidNativeBridge){
            console.log('androidNativeBridge ready');
        }
        setTimeout(()=>{
            self.clearLocalDir();
            // self.openMask(<PointTip points={3}/>,{
            //     cancelClose: true,
            //     autoClose: 0
            // })
            // self.getSIMPhoneNumber();
        },500);

    }

    render() {
        let self=this;
        let {appKey,channelKey} =self.state;
        let App=channelKey?Apps[`${appKey}_${channelKey}`]:Apps[appKey];
        if(App){
            return (
                <>
                    <AppGlobalAudioPlayer ref={(el)=>{self.audio=el;}}/>
                    <AppDrawerWraper ref={(el)=>{self.appDrawerWraper=el;}}/>
                    {/*<AppSnackMessage ref={(el)=>{self.appSnackMessage=el;}} />*/}
                    <AppMaskWrapper ref={(el)=>{self.maskWrapper=el;}} />
                    <div className={cx('app-content-wrap',appKey)}>
                        {<App.component />}
                    </div>
                </>
            );
        }else{
            return null
        }

    }
}

export default Module;
