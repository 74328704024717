import asyncComponent from '@core/AsyncComponent';
// import hmf_wear from '@page/app/hmf_wear';
// import test from '@page/app/test';
const apps={
    "hmf_wear":{
        // component:test
        name:'甘肃移动',
        component:asyncComponent(() => import("@page/app/hmf_wear"),{closeLoadingView:true})
    },
    "hmf_wear_cmhe-gzh":{
        // component:test
        name:'贵州移动',
        component:asyncComponent(() => import("@page/app/hmf_wear_cmhe-gzh"),{closeLoadingView:true})
    }
    // hmf_audio_room:{
    //     // component:tongbuxye
    //     // component:asyncComponent(() => import("@page/app/hmf_audio_room"),{closeLoadingView:true})
    //     component:asyncComponent(() => import("@page/app/hmf_wear"),{closeLoadingView:true})
    // }
}
export default apps;
