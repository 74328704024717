class EventDispatcher {
    constructor(){
        this._listeners = {};
        this.addEventListener = function(type, listener) {
            if(typeof (type)==='string'){
                let listeners = this._listeners[type];
                if (!listeners) listeners = this._listeners[type] = [];
                listeners.push(listener);
            }else if(type.length){
                for (let i in type){
                    let t=type[i];
                    if(t){
                        let listeners = this._listeners[t];
                        if (!listeners) listeners = this._listeners[t] = [];
                        listeners.push(listener);
                    }
                }
            }
        };
        this.removeEventListener = function(type, listener) {
            let listeners = this._listeners[type];
            if (!listeners || !listeners.length) return;
            for (let i in listeners) {
                if (listeners[i] === listener) {
                    listeners.splice(i, 1);
                    if (!listeners.length) delete this._listeners[type];
                    break;
                }
            }
        }
        this.clearEventListener = function(type) {
            if(type) {
                delete this._listeners[type];
            }else {
                this._listeners = {};
            }
        }
        this.dispatchEvent = function(type,data,param) {
            let listeners = this._listeners[type];
            if (!listeners || !listeners.length) return;
            let event={
                type:type,
                data:typeof data === 'function'? data():data,
                param:param
            }
            for (let i in listeners) {
                listeners[i].call(null, event);
            }
        }
    }
}
export default EventDispatcher
