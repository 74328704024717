import 'babel-polyfill';
import React from "react";
import ReactDOM from "react-dom";
import webViewBridge from '@vendor/webViewBridge';
import Run from './run';
import '@css/index.css';
import '@css/animate.css';

const insertScript = (path,props)=>{
    let ts = document.createElement("script");
    ts.src = path;
    if(typeof props ==='object'){
        Object.keys(props).forEach((k)=>{
            ts.setAttribute(k,props[k]);
        })
    }
    let sn = document.getElementsByTagName("script")[0];
    sn.parentNode.insertBefore(ts, sn);
};

window.insertScript=insertScript;
window.PointerEvent = void 0;
window.jsNativeBridge = {};
window.addEventListener("mousewheel", (e) => {
    if (e.deltaY === 1) {
        e.preventDefault();
    }
});
let ug = window.navigator.userAgent.toLowerCase();
let hname = window.location.hostname.toLowerCase();
let isIPad = ug.indexOf('ipad')>=0;
let isAndroid = ug.indexOf('android')>=0;
let isApp = ug.indexOf('hmfapp')>=0;
let isWebMoniApp = ug.indexOf('webmoni')>=0;
let isIPod = ug.indexOf('ipod')>=0;
let isIPhone = ug.indexOf('iphone')>=0;
let isIOS = isIPad || isIPhone || isIPod;
let isDev = hname.indexOf('localhost')>=0 || hname.indexOf('dev-')>=0 || hname.indexOf('dev.')>=0;

window.PLF = {
    isApp,isIPad,isIPod,isIPhone,isIOS,isDev,isAndroid,isWebMoniApp
};
if(window.PLF.isApp){
    webViewBridge && webViewBridge(window);
    if(window.PLF.isWebMoniApp){
        insertScript(`${window.PUBLIC_URL}/assets/js/iload_v4.1.25.js`);
        insertScript(`${window.PUBLIC_URL}/assets/js/lame.all.js`);
        insertScript(`${window.PUBLIC_URL}/assets/js/recorder-manager/index.umd.js`);
    }
}else{
   insertScript(`${window.PUBLIC_URL}/assets/js/iload_v4.1.25.js`);
   insertScript(`${window.PUBLIC_URL}/assets/js/lame.all.js`);
   insertScript(`${window.PUBLIC_URL}/assets/js/recorder-manager/index.umd.js`);
}

ReactDOM.render(<Run/>,document.getElementById('app_root'));
