import React, {Component} from '@core/ReactComponent';
import cx from 'classnames';
import './index.css';

const defOptions={
    cancelClose: false,
    autoClose:false,
    onClose:'',
};
class Module extends Component {
    constructor(props,context){
        super(props,context);
      let self=this;
    }
    createInitState(props,context){
       let state={
           open:false,
           content:''
       };
       state.option=Object.assign({},defOptions);
       return state;
    }
    initCustomFunction(self){
        return {
            open:(content,option={})=>{
                if(content){
                    let _opt=Object.assign({},defOptions,option);
                    self.setState({
                        open:true,
                        content,
                        option:_opt
                    },()=>{
                        if(_opt.autoClose){
                            self.timer=setTimeout(self.close,~~_opt.autoClose);
                        }
                    })
                }
            },
            close:()=>{
                let {option} = self.state;
                if(self.timer){
                    clearTimeout(self.timer);
                    self.timer=undefined;
                }
                self.setState({
                    open:false,
                    content:'',
                    option:''
                },()=>{
                    if(typeof option.onClose ==='function'){
                        option.onClose();
                    }
                });
            }
        }
    }
    //UNSAFE_componentWillReceiveProps(props) {}
    // UNSAFE_componentWillMount() {
    // }

    // componentDidMount() {
    // }

    //componentWillUnmount() { }
    render() {
        let self = this;
        let state=self.state;
        let {open,content}=state;
        return (
            <div className={cx('module-view-mask-wrapper',self.props.className,{show:open})} onClick={self.close}>
                <div className={'mask-content-wrap'}>{content}</div>
            </div>
        );
    }
}

export default Module;
