export default function (window) {
    let browserType = {
        types: function() {
            let u = window.navigator.userAgent;
            return {
                trident: u.indexOf('Trident') > -1,
                presto: u.indexOf('Presto') > -1,
                webKit: u.indexOf('AppleWebKit') > -1,
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1,
                mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/),
                mac: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
                iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1,
                iPad: u.indexOf('iPad') > -1,
                webApp: u.indexOf('Safari') === -1

            };
        }(),
        language: (window.navigator.browserLanguage || window.navigator.language).toLowerCase()
    };
    let setupWebViewJavascriptBridge = function(callback) {
        if(window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge);
        }
        if(window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }else{
            window.WVJBCallbacks = [callback];
        }
        let WVJBIframe = window.document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        if(browserType.types.android) {
            WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
        } else if(browserType.types.mac || browserType.types.iPhone || browserType.types.iPad) {
            WVJBIframe.src = 'https://__bridge_loaded__';
        }
        window.document.documentElement.appendChild(WVJBIframe);
        setTimeout(function() {
            window.document.documentElement.removeChild(WVJBIframe);
        }, 0);
    };
    setupWebViewJavascriptBridge(function(bridge){

        // bridge.registerHandler('NativeCallJS', function(data, responseCallback) {
        //     let responseData = {
        //         'Javascript Says': 'Right back atcha!'
        //     };
        //     console.log('Native call JS with ', data);
        //     responseCallback(responseData);
        // });
        // bridge.registerHandler('paymentResult', function(data, responseCallback) {
        //     var responseData = {
        //         'Javascript Says': 'response!'
        //     };
        //
        //     console.log('Native call JS with ', data);
        //     responseCallback(responseData);
        // });
        let JNBridge = window.jsNativeBridge ||{};
        JNBridge.bridge = bridge;
        JNBridge.ver = JNBridge.ver || '';
        JNBridge.callHandler = function(apiKey,param,callback){
            JNBridge.exec(apiKey,param,callback);
        };
        JNBridge.registerHandler = function (apiKey,callback){
            // console.log('registerHandler',apiKey,typeof bridge.registerHandler);
            bridge.registerHandler(apiKey,callback);
        };
        JNBridge.exec = function (apiKey,param,callback){
            let params={api:apiKey};
            if(param){
                params.param = param;
            }
            bridge.callHandler('jsCallNativeHandle',params,callback);
        }
        if(browserType.types.mac || browserType.types.iPhone || browserType.types.iPad){
            window.iosNativeBridge = JNBridge;
            console.log('iosNativeBridge ready');
        }else{
            window.androidNativeBridge = JNBridge;
            console.log('androidNativeBridge ready');
        }
    });
}