import React from 'react';
import PropTypes from "prop-types";
import ReactDOM from 'react-dom';

const context={
    app:     PropTypes.object,
    parent:  PropTypes.object,
    parentPage:PropTypes.object,
    pageContext:  PropTypes.object,
    classes: PropTypes.object
};
class Module extends React.Component {
    constructor(props, context) {
        super(props, context);
        let self=this;
        if(context){
            self.app = context.app;
            self.parent = context.parent;
        }
        if(self.initCustomFunction){
            let funConf = self.initCustomFunction(self);
            if('object' === typeof funConf){
                Object.keys(funConf).forEach((key)=>{
                    if(funConf[key] && 'function' === typeof funConf[key]){
                        self[key] = funConf[key];
                    }
                })
            }
        }
        this.state = self.createInitState ? self.createInitState(props, context): null;
    }
    static childContextTypes = context;
    static contextTypes = context;
    UNSAFE_componentWillMount() {
        this.app = this.context.app;
        this.parent = this.context.parent;
    }
    componentDidCatch(error, info) {
        console.error(error);
        console.log(info);
        if(this.app){
            this.app.alert('Sorry，检测发现运行异常，请向客服反馈。',true,false);
        }
    }
    pagePush(param,param2){
        this.props.history.push(param);
    }
    pageReplace(param,param2){
        this.props.history.replace(param);
    }
    getChildContext(){
        return  Object.assign({},this.context, {parent:this});
    }
}
Module.contextProps = context;
export default React;
export {ReactDOM, Module as Component};
