import React, {Component} from '@core/ReactComponent';

import audioEmpty from '@media/empty.mp3';
const eventNames =['error','ratechange','progress','seeked','volumechange','pause','play','playing','loadedmetadata','timeupdate','ended','emptied','canplay'];
const defOptions = {
    volume:1,
    playbackRate:1,
    loop:false,
    muted:false,
    preload:true,
}
const hostMain = {
  host:'main-1256408168.file.myqcloud.com',
  target:'static.huimanfen.com'
};
const hostTest = {
    host:'test-1256408168.file.myqcloud.com',
    target:'static-test.huimanfen.com'
};

class Module extends Component {
    // constructor(props,context){
    //     super(props,context);
    // }
    createInitState(props,context){
       let state={
           src:'',
           options:Object.assign({},defOptions),
           events:{}
       };
       return state;
    }
    initCustomFunction(self){
       return {
           handleEvent:(name,e)=>{
                let {events} = self.state;
                if(events && typeof events[name] === 'function'){
                    events[name](self.audio,e);
                }
           },
           isPaused:()=>{
             return self.audio.paused;
           },
           reset:(callback)=>{
               if(self.audio){
                   self.audio.pause();
                   self.state.src='';
                   self.state.events={};
                   self.audio.src='';
                   callback && callback();
               }
           },
           setUrl:(src,events,options,callback)=>{
               if(src.indexOf('http://')>=0){
                   src=src.replace('http://','https://');
               }
               if(src.indexOf(hostMain.host)>=0){
                   src=src.replace(hostMain.host,hostMain.target);
               }
               if(src.indexOf(hostTest.host)>=0){
                   src=src.replace(hostTest.host,hostTest.target);
               }
               self.reset(()=>{
                   if(self.audio){
                       let _options=Object.assign({},defOptions,options);
                       self.state.src=src;
                       self.state.events=events;
                       self.state.options=_options;
                       self.audio.src=src;
                       self.audio.preload = _options.preload;
                       self.load();
                       self.audio.volume = _options.volume;
                       self.audio.playbackRate = _options.playbackRate;
                       self.audio.loop = _options.loop;
                       self.audio.muted = _options.muted;
                       callback && callback();
                   }
               })
           },
           load:()=>{
               if(self.state.src){
                    self.audio.load();
               }
           },
           play:()=>{
               self.audio && self.audio.play();
           },
           pause:()=>{
               self.audio && self.audio.pause();
           },
           fastSeekPersent:(persent)=>{
               if(self.audio){
                   let {duration} = self.audio;
                   let targetSeconds = Math.floor(duration * persent);
                   if(self.audio.fastSeek){
                       self.audio.fastSeek (targetSeconds);
                   }else{
                       self.audio.currentTime = targetSeconds;
                   }
               }
           },
           getAudioStatus:()=>{
               let status={
                   currentSrc:self.audio.currentSrc,
                   paused:self.audio.paused,
                   networkState:self.audio.networkState,
                   loop:self.audio.loop,
                   volume:self.audio.volume,
                   muted:self.audio.muted,
                   duration:self.audio.duration,
                   currentTime:self.audio.currentTime,
                   ended:self.audio.ended,
                   seekable:self.audio.seekable,
                   seeking:self.audio.seeking,
                   preload:self.audio.preload,
                   playbackRate:self.audio.playbackRate,
               }

               return status;
           },
           prePlay:()=>{
               window.document.removeEventListener('touchstart', self.prePlay);
               if(!self.state.src){
                   self.setUrl(audioEmpty,{},{},()=>{
                       self.play();
                   });
               }
           },
           set:(key,value)=>{
               if(typeof key==='string' && key.length){
                   if(self.audio){
                       self.audio[key]=value;
                       switch (key) {
                           case 'muted':
                               self.handleEvent('mutedChange',self.audio);
                               break;
                           case 'loop':
                               self.handleEvent('loopChange',self.audio);
                               break;
                           default:
                       }
                   }
               }
           },
           clear:()=>{
               if(self.audio){
                   self.state.events={};
                   self.state.src='';
                   self.audio.pause();
                   self.audio.src='';
               }
           },
           init:()=>{
               if(self.audio){
                   eventNames.forEach((name)=>{
                       self.audio.addEventListener(name,(e)=>{
                           self.handleEvent(name,e);
                       })
                   });
                   window.document.addEventListener('touchstart', self.prePlay);
               }
           },
       }
    }
    //UNSAFE_componentWillReceiveProps(props) {}
    //UNSAFE_componentWillMount() {
    //}
    componentDidMount() {
        setTimeout(this.init,300)
    }

    componentWillUnmount() {
        this.unmounted=true;
    }
    render() {
        let self = this;
        //let state=self.state;
        // let {match,location}=self.props
        return (
            <audio src={audioEmpty} ref={(el)=>{self.audio=el;}} />
        );
    }
}

export default Module;
