import React, { Component } from "react";

// import loading from '@img/logo_002.png';
const defOption={
    closeLoadingView:false,
}
export default (importComponent,options) => {
    const _option = Object.assign({},defOption,options);
    class AsyncComponent extends Component {
        constructor(props,context) {
            super(props,context);
            this.state = {
                component: null
            };
        }
        // async componentDidMount() {
        //     let self=this;
        //     const { default: component } = await importComponent();
        //     // setTimeout(()=>{
        //     self.setState({
        //         component: component
        //     });
        //     // },30000)
        // }
        closeLoadingView(){
            let viewLoading = document.getElementById('loading');
            if(viewLoading){
                viewLoading.style.display='none';
                viewLoading.remove();
            }
        }
        componentDidMount() {
            let self=this;
            importComponent().then(({ default: component } )=>{
                self.setState({
                    component: component
                },()=>{
                    if(_option.closeLoadingView){
                        self.closeLoadingView()
                    }
                });
            });
        }
        render() {
            let C = this.state.component;
            return (C ? <C {...this.props} /> :null)
        }
    }

    return AsyncComponent;
}