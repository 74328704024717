import axios from 'axios';
import Utils from './Utils';
class API {
    constructor(config,env){
        const _self=this;
        const _env=env;
        const _appConf=config;
        const _apiInstanceConf=_appConf.apiInstance.api;
        let _globalQuerys,
            _globalError,
            _globalHeaders=Object.assign({
                'Content-Type': 'application/json',
            }, _appConf.apiInstance.header || {});
        const _cache={};
        const _lockKeyCache={}
        const _timeExpress=(_env.cacheExpires || 300 ) *1000;
        /* 数据缓存方法*/
        //存储数据
        _self.setCache=function(scopePath,paramKey,data){
            if(scopePath && paramKey){
                let time=Date.now();
                let _scopeCache=_cache[scopePath];
                if(!_scopeCache){
                    _cache[scopePath]=_scopeCache={};
                }
                _scopeCache[paramKey]={
                    data:data,
                    updateTime:time
                }
            }
        };
        _self.reset=function(){
            _self.removeGlobalHeader('Authorization');
            _self.removeGlobalHeader('X-LX-Token-ID');
            _self.removeGlobalHeader('X-LX-User-ID');
            Object.keys(_cache).forEach((key)=>{
                delete _cache[key];
            });
        };
        //删除数据
        _self.removeCache=function(scopePath,paramKey){
            if(scopePath && paramKey){
                let scope=_cache[scopePath];
                if(scope){
                    delete scope[paramKey];
                }
            }
        };
        //清除数据
        _self.clearCacheScope=function(scopePath){
            delete _cache[scopePath];
        };
        //读取数据
        _self.getCache=function(scopePath,paramKey){
            let data;
            let time=Date.now();
            if(scopePath && paramKey){
                let _scopeCache = _cache[scopePath];
                if(_scopeCache){
                    let c=_scopeCache[paramKey];
                    if(c){
                        if((time-c.updateTime)<_timeExpress){
                            data=c.data;
                        }else{
                            _self.removeCache(scopePath,paramKey);
                        }
                    }
                }
            }
            return data;
        };
        /* 数据缓存方法 end*/

        /* 设置ajax全局对象方法*/
        //设置全局请求头
        _self.setGlobalHeaders=function (obj) {
            _globalHeaders=Object.assign(_globalHeaders,obj);
        }
        _self.getGlobalHeaders=function (){
            return Object.assign({},_globalHeaders);
        }
        //删除全局请求头
        _self.removeGlobalHeader=function (key) {
            if(_globalHeaders && _globalHeaders[key]!==undefined){
                delete _globalHeaders[key]
            }
        }
        //设置url全局查询参数
        _self.setGlobalQuerys=function (obj) {
            let o=_globalQuerys || {};
            _globalQuerys=Object.assign(o,obj);
        }
        //设置ajax全局错误回调函数
        _self.setGlobalError=function (fun) {
            _globalError=fun;
        }

        /* 设置ajax全局对象方法 end*/

        _self.getQueryString=(querys)=>{
            let strs=[];
            for(let key in querys){
                let v=querys[key];
                let t=typeof v;
                if( 'object'===t || t.length >=0 ){
                    v=JSON.stringify(v);
                }
                if(v){
                    strs.push(key+'='+v);
                }
            }
            return strs.join('&');
        };

        _self.axios=axios;
        _self.axios.defaults.timeout = 1000*20;
        let _defOpt={
            method:'get',
            baseURL:'',
            timeout:30000,
            responsetype:'json'
        }
        _self.sendRequest=(baseURL,option,param={},callback,error,retryCount)=>{
            retryCount = retryCount || 0;
            let _now = Date.now();
            let useCache=false;
            if( _env.useCache && option.useCache){
                useCache=true;
            }
            let _customErrorCode=param['customCode'];
            if(_customErrorCode){
                delete param['customCode'];
            }
            if(param.useCache!==undefined){
                useCache=param.useCache? true:false;
                delete param['useCache'];
            }
            let _url=option.url;
            if(param.urlParams && typeof param.urlParams ==='object'){
                _url=option.url.format(param.urlParams);
                delete param['urlParams'];
            };
            let cacheData;
            let scopePath=Utils.hashCode(_url).toString();
            let paramKey = Utils.hashCode(JSON.stringify(param)).toString();
            let lockKey = scopePath+'_'+paramKey;
            if(useCache && callback){
                cacheData=_self.getCache(scopePath,paramKey);
                env.envKey!=='prod' && console.log(`API [${_url}] scopePath:[${scopePath}] paramKey:[${paramKey}] match data:[${cacheData?'yes':'no'}]`);
            }
            if(cacheData){
                if(typeof callback ==='function'){
                    callback(cacheData);
                }
            }else{
                if(lockKey){
                    if(_lockKeyCache[lockKey] && _now - _lockKeyCache[lockKey] < (30000)){
                        return false;
                    }else{
                        _lockKeyCache[lockKey]=_now
                    }
                }
                let opt=Object.assign(_defOpt,option);
                opt.baseURL=baseURL;
                opt.url=_url;
                opt.port=443;
                if(_globalHeaders){
                    opt.headers=_globalHeaders;
                }
                if(opt['x_lx_auth']){
                    opt.headers=opt.headers || {};
                    opt.headers['X-LX-AUTH']=1;
                }
                if(opt.method==='get'){
                    opt.params = Object.assign( _globalQuerys || {}, param || {});
                }else{
                    opt.params = _globalQuerys || {};
                    opt.data = param;
                }
                let successCall=function(res){
                    delete _lockKeyCache[lockKey];
                    if(useCache){
                        if(res.data && !(res.data._notice || res.data._error)){
                            _self.setCache(scopePath,paramKey,res.data);
                        }
                    }
                    callback && callback(res.data);
                }
                let errorCall=function(err){
                    delete _lockKeyCache[lockKey];
                    if(err && err.response && err.response.data && err.response.data._notice){
                        callback && callback(err.response.data);
                    }else if(err && err.request){
                        if(typeof error === 'function'){
                            error(err);
                        }else if (typeof _globalError === 'function') {
                            if(_customErrorCode && _customErrorCode[err.request.status]){
                                _customErrorCode[err.request.status](err);
                            }else{
                                _globalError(err);
                            }
                        }
                    }else{
                        error && error(err);
                    }
                }
                let promiss = axios(opt);
                if(typeof callback === 'function'){
                    promiss.then(successCall).catch(errorCall);
                }else{
                    return promiss;
                }
            }
        };
        _self.transformApi=(instanceConf)=>{
            function renderApi (root, apiConfigs) {
                Object.keys(apiConfigs).forEach((key)=>{
                    let apiConf=apiConfigs[key];
                    if(apiConf.url && apiConf.method){
                        let _host=apiConf.localHost?apiConf.localHost:_env.apiHost;
                        root[key]=function (baseURL,axiosOption,param,callback,error) {
                            _self.sendRequest(baseURL,axiosOption,param,callback,error)
                        }.bind(_self,_host,apiConf);
                    }else{
                        let obj=root[key]={};
                        renderApi(obj,apiConf);
                    }
                });
            }
            renderApi(_self, instanceConf);
        };
        _self.transformApi(_apiInstanceConf || {});
    }
}
export default API