import React from 'react';
// import fundebug from 'fundebug-javascript';
import asyncComponent from '@core/AsyncComponent';
import {MemoryRouter} from "react-router-dom";

import Root from '@js/Root';

let hostname = window.location.hostname;
let EnvConf;
if(hostname.indexOf('d08wear.')>=0 || hostname.indexOf('dev.')>=0 || hostname.indexOf('dev-')>=0 || hostname.indexOf('localhost')>=0 || hostname.indexOf('127.0.0.1')>=0){
    EnvConf=asyncComponent(() => import("@cfg/app_config_dev"));
}else{
    EnvConf=asyncComponent(() => import("@cfg/app_config_prod"));
}
// console.log(theme);
export default class Module extends React.Component{
    render(){
        return <EnvConf><MemoryRouter initialEntries={['/']} initialIndex={0}>
            <Root />
        </MemoryRouter></EnvConf>
    }
}
