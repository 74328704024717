export default class MemeryStorage {
    constructor(){
        let self=this;
        self.catch={};
        self.getItem=(key)=>{
            return self.catch[key];
        };
        self.setItem=(key,value)=>{
            self.catch[key]=value;
        };
        self.removeItem=(key)=>{
            delete self.catch[key];
        };
        return self;
    }
}